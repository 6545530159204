import 'react-multi-carousel/lib/styles.css';


import React from 'react'
import styled from 'styled-components'

export const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
margin-top: 12px;
      font-size: 32px;
  }
`;

export const Desc = styled.div`
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: -40px;
`

const Skill = styled.div`
  width: 100%;
  max-width: 500px;
  background: ${({ theme }) => theme.card};
  border: 0.1px solid #854CE6;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;
  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }
  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }
`

const SkillTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 15px;
  text-align: center;
`

const SkillList = styled.div`
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 5px;
`

const SkillItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 80};
  border: 1px solid ${({ theme }) => theme.text_primary + 80};
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 12px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    padding: 6px 12px;
  }
`

const SkillImage = styled.img`
  width: 24px;
  height: 24px;
`

export const skills2 = [
  {
    title: "Languages & Frameworks",
    skills: [
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "F#",
        image:
          "https://fsharp.org/img/logo/fsharp256.png",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "Postgresql",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
      {
        name: "TypeScript",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg",
      },
      {
        name: "React & React Native",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
      },
      {
        name: "R",
        image:
          "https://www.r-project.org/Rlogo.png",
      },
      {
        name: "Arduino",
        image:
          "https://cdn.worldvectorlogo.com/logos/arduino-1.svg",
      },
      {
        name: "Raspberry Pi",
        image:
          "https://www.raspberrypi.org/app/uploads/2018/03/RPi-Logo-Reg-SCREEN.png",
      },
    ],
  },
  {
    title: "AI, Machine Learning & Data",
    skills: [
      {
        name: "Hugging Face",
        image:
          "https://huggingface.co/front/assets/huggingface_logo-noborder.svg",
      },
      {
        name: "PyTorch",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/1/10/PyTorch_logo_icon.svg",
      },
      {
        name: "Pandas",
        image:
          "https://pandas.pydata.org/static/img/pandas_white.svg",
      },
      {
        name: "Numpy",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/3/31/NumPy_logo_2020.svg",
      },
      {
        name: "Scikit-learn",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/0/05/Scikit_learn_logo_small.svg",
      },
      {
        name: "Matplotlib",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/8/84/Matplotlib_icon.svg",
      },
      {
        name: "Seaborn",
        image:
          "https://seaborn.pydata.org/_images/logo-mark-lightbg.svg",
      },
      {
        name: "Apache Spark",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/f/f3/Apache_Spark_logo.svg",
      },
      {
        name: "MLflow",
        image:
          "https://avatars.githubusercontent.com/u/39938107?v=4",
      },
      {
        name: "PowerBI",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/cf/New_Power_BI_Logo.svg",
      },
      {
        name: "SAS VA & EG",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/1/10/SAS_logo_horiz.svg",
      },
    ],
  },
  {
    title: "Design & Prototyping",
    skills: [
      {
        name: "Figma",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg",
      },
      {
        name: "Adobe Illustrator",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/f/fb/Adobe_Illustrator_CC_icon.svg",
      },
      {
        name: "Adobe After Effects",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/cb/Adobe_After_Effects_CC_icon.svg",
      },
      {
        name: "Adobe InDesign",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/4/48/Adobe_InDesign_CC_icon.svg",
      },
    ],
  },
  {
    title: "Cloud Services and Others",
    skills: [
      {
        name: "AWS (Elastic Beanstalk, Cognito, KMS, EC2, S3 Bucket, Amplify, Sagemaker)",
        image: "https://a0.awsstatic.com/libra-css/images/logos/aws_logo_smile_1200x630.png",
      },
      {
        name: "Azure (AI-services)",
        image: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
      },
      {
        name: "Git",
        image:
          "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      // {
      //   name: "Docker",
      //   image:
      //     "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      // },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg",
      },
    ],
  },
];


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>  I’ve worked with a wide range of programming languages, AI/ML tools, cloud services, and data visualization platforms. </p>
                        <SkillsContainer>
                          {skills2.map((skillv) => (
                            <Skill>
                              <SkillTitle>{skillv.title}</SkillTitle>
                              <SkillList>
                                {/* Screen going black here.... */}
                                {skillv.skills.map((item) => (
                                <SkillItem key={item.name}>
                                  <SkillImage src={item.image}/>
                                  {item.name}
                                </SkillItem>
                              ))}
                              </SkillList>
                            </Skill>
                          ))}

                        </SkillsContainer>
                        {/* <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" />
                                <h5>Brand Identity</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" />
                                <h5>Logo Design</h5>
                            </div>
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                        </Carousel> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
