import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Experience } from "./components/Experience";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import ProjectDetails from "./components/ProjectDetails";
import { Education } from "./components/Education";

function App() {
  const [openModal, setOpenModal] = useState({ state: false, project: null });

  return (
    <div className={`App ${openModal.state ? 'blur' : ''}`}>
      <NavBar />
      <Banner />
      <Skills />
      <Projects openModal={openModal} setOpenModal={setOpenModal} />
      <Experience />
      <Contact />
      <Footer />
      {openModal.state && (
        <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
      )}
    </div>
  );
}

export default App;