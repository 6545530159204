
import 'react-multi-carousel/lib/styles.css';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ExperienceCard from './ExperienceCard';

import React from 'react'
import styled from 'styled-components'



const TimelineSection = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 10px auto; /* Center the section horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;


export const experiences = [
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/v2/C4E0BAQFnjrkc6WE0KQ/company-logo_100_100/company-logo_100_100/0/1678953148195/landbrugsstyrelsen_logo?e=1732147200&v=beta&t=OJMx1G0jAjvhHE4J21Ws2HOjRqGzRIOUsI0WO3QHN6M", // Replace with an actual URL to the logo
    role: "Student Data Scientist",
    company: "Landbrugsstyrelsen",
    date: "Jan. 2023 - Present",
    desc: `In my role as a Student Data Scientist at Landbrugsstyrelsen, I've been responsible for driving data-driven decisions through comprehensive data analysis, AI and ML model development. My work has involved using Python, R and SQL. I've developed predictive models, including regression analyses, decision trees, neural networks and time series forecasting, which have been instrumental in improving operational efficiency. Additionally, I've leveraged Natural Language Processing (NLP) techniques to perform detailed text analysis, providing deeper insights from unstructured data.`,
    skills: [
      "Python",
      "R",
      "SQL",
      "Huggingface",
      "PyTorch",
    ],
  },
  {
    id: 1,
    img: "https://media.licdn.com/dms/image/v2/C4D0BAQEC2qYlsnIvww/company-logo_100_100/company-logo_100_100/0/1631608627994/wdp_x_logo?e=1732147200&v=beta&t=HKzCr0366xXcb3C7sgu1WNRvFloKnBc2xyIIEcFqL4A", // Replace with an actual URL to the logo
    role: "Student UX Designer",
    company: "WDP X",
    date: "Aug. 2022 - Okt. 2022",
    desc: `As a Student UX Designer at WDP X, I was tasked with enhancing user experiences through research & design. I developed wireframes and interactive prototypes using Figma. My role also involved conducting user interviews and facilitating workshops with clients to gather feedback and refine design iterations.`,
    skills: [
      "User Experience Design",
      "Wireframing",
      "Prototyping",
      "Figma",
      "User Research",
      "Client Workshops",
    ],
  },
  {
    id: 2,
    img: "https://media.licdn.com/dms/image/v2/C4E0BAQEUIvvNqZJYMw/company-logo_100_100/company-logo_100_100/0/1630601792168/gyldendal_logo?e=1732147200&v=beta&t=LNtwP0P25uBtWIuGL6f-k0ZSyAgeyIxUClatT-fexd8", // Replace with an actual URL to the logo
    role: "Digital Assistant",
    company: "Gyldendal",
    date: "Nov. 2019 - Aug. 2022",
    desc: `During my time as a Digital Assistant at Gyldendal, I was responsible for maintaining and updating various digital platforms, ensuring user experiences across all web properties. I played a key role in  projects, such as migrating content between platforms using Sitecore, and took charge of content creation, including layout design and coding in HTML and CSS. Additionally, I contributed to the creative process by producing digital illustrations and graphics using Adobe Photoshop, Illustrator, After Effects, and InDesign. I had a major part in Teknologiforståelse and Danskbrikkerne, two of Gyldendal's digital learning platforms.`,
    skills: [
      "HTML",
      "CSS",
      "Adobe Illustrator",
      "Adobe After Effects",
      "Adobe InDesign",
      "Web Design",
    ],
  },
  {
    id: 3,
    img: "https://media.licdn.com/dms/image/v2/D4D0BAQHV4EQdLpPtyw/company-logo_100_100/company-logo_100_100/0/1723458161360/roskilde_kommune_logo?e=1732147200&v=beta&t=TrO3K7o9CA1s3laSwFO1QpWKDtZvIWLg-BaqWMuuZnI", // Replace with an actual URL to the logo
    role: "Pedagogue & Substitute Teacher",
    company: "Roskilde Kommune",
    date: "Aug. 2017 - Aug. 2019",
    desc: `In my dual role as a Pedagogue and Substitute Teacher at Roskilde Kommune, I developed strong skills in classroom management, particularly in high-pressure environments. My work involved supporting students with special needs, fostering an inclusive classroom environment, and collaborating closely with other educators to ensure a cohesive learning experience. I was frequently called upon to take leadership in classrooms, manage behavioral challenges, and adapt teaching strategies to meet diverse student needs.`,
    skills: [
      "Classroom Management",
      "Leadership",
      "Adaptability",
      "Student Support",
    ],
  },
  // {
  //   id: 4,
  //   img: "https://media.licdn.com/dms/image/v2/C4D0BAQHSzTOkgquJbw/company-logo_100_100/company-logo_100_100/0/1650532830883/kvickly_logo?e=1732147200&v=beta&t=ekTY87NT4Om2-kbDc_NExxkhr5lR4WyaWvI2NVM0-5Q", // Replace with an actual URL to the logo
  //   role: "Closing Manager",
  //   company: "Kvickly",
  //   date: "Jul. 2014 - Mar. 2018",
  //   desc: `As a Closing Manager at Kvickly, I was entrusted with the critical responsibility of overseeing store operations during closing hours. My duties included securing the store, managing end-of-day reports, and ensuring that all financial transactions were accurately recorded. I was also responsible for training and mentoring new employees, helping them to integrate into the team and understand store procedures. My role required strong leadership skills, attention to detail, and the ability to manage multiple tasks simultaneously, all while maintaining a high level of customer service.`,
  //   skills: [
  //     "Team Management",
  //     "Employee Training",
  //     "Retail Operations",
  //     "Cash Handling",
  //     "Customer Service",
  //     "Conflict Resolution",
  //     "Time Management",
  //     "Inventory Management",
  //     "Leadership",
  //     "Attention to Detail",
  //   ],
  // },
];



export const Experience = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  

  return (
    <section className="experience" id="experiences">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Experience</h2>
          {/* <p>Your experience description goes heresdssd </p> */}
          <TimelineSection>
          <Timeline>
            {experiences.map((experience, index) => (
              <TimelineItem key={experience.id}>
                <TimelineSeparator>
                  <TimelineDot
                    variant="outlined"
                    style={{ borderColor: '#7FACD6' }} // Customize the color of the dot's border
                  />
                  {index !== experiences.length -1 && (
                    <TimelineConnector style={{ background: '#7FACD6' }} /> // Customize the connector color
                  )}
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: '12px',
                    px: 2,
                  }}
                >
                  <ExperienceCard experience={experience} />
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </TimelineSection>
        </div>
      </div>
    </div>
  </section>
  
    );
  };
