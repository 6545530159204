import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12} className="text-center">
            <h2>Currently a part of these initiatives</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={12}>
            <Row className="justify-content-center">
              <Col md={4} className="d-flex flex-column align-items-center my-3">
                <img 
                  src="https://meoo.dk/public/meoo/nextgen_blacktagline%201-400h.png" 
                  alt="ITUNextGen" 
                  className="img-fluid"
                  style={{ maxWidth: '200px' }} // Adjust image size
                />
              </Col>
              <Col md={4} className="d-flex flex-column align-items-center my-3">
                <img 
                  src="https://meoo.dk/public/meoo/health_innovators_logo%201%20(1)-400h.png" 
                  alt="HealthInnovators" 
                  className="img-fluid"
                  style={{ maxWidth: '200px' }} // Adjust image size
                />
              </Col>
              <Col md={4} className="d-flex flex-column align-items-center my-3">
                <img 
                  src="https://images.heapadmin.com/cdn-cgi/image/width=310,height=310/https://heap-nexus.s3.eu-west-1.amazonaws.com/store/512e9b66f334b286c38db3ecb5ccb59986b6052e1b354dcdcc991e3b299c" 
                  alt="Roskilde Festival Ildsjæl" 
                  className="img-fluid"
                  style={{ maxWidth: '90px' }} // Adjust image size
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={4} className="d-flex flex-column align-items-center my-3">
                <p className="text-center mt-3">Currently a part of the startup incubator ITUNextGen</p>
              </Col>
              <Col md={4} className="d-flex flex-column align-items-center my-3">
            
                <p className="text-center mt-3">Currently a part of the startup incubator HealthInnovators</p>
              </Col>
              <Col md={4} className="d-flex flex-column align-items-center my-3">
              
                <p className="text-center mt-3">Roskilde Festival Ildsjæl where i create data analysis</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
