import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import daMedSumImage from '../assets/img/DaMedSum.png';
import meoo from '../assets/img/meoo.png';
import molio from '../assets/img/molio.png';
import China from '../assets/img/China.png';
import Zeeguu from '../assets/img/Zeeguu.png';

import { Container2, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectStyle'

export const AIML = [
  {
    id: 10,
    title: "Summarising Danish Medical Text using NLP",
    date: "Sep 2023 - Dec 2023",
    description:
      "In this research project, we investigated the use of deep learning techniques to fine-tune established models such as DanSumT5 and Google mT5 for summarising Danish medical text. The goal was to create summaries accessible to people with a non-medical background. Model training was conducted on the LUMI supercomputer using data from Netdoktor.dk and Sundhed.dk. The project contributed a model, which is publicly available in four different variants on HuggingFace.",
    image: daMedSumImage,
    tags: [
      "Pytorch",
      "Python",
      "Huggingface",
      "NLP",
      "DeepSeed",
      "LUMI",
    ],
    category: "research",
    github: "",  // If available, add the link to the repository or related project
    webapp: "",  // If available, add the link to the project demo or website
  },
  {
    id: 11,
    title: "Developing a Danish-Specific Subword Tokenizer",
    date: "Aug 2024 - Ongoing",
    description:
      "Our objective is to effectively develop a subword tokenizer optimized for the Danish language, addressing the linguistic nuances inherent to Danish. This involves creating a tokenizer that can accurately segment Danish text into meaningful subword units, thereby enhancing the pre-processing stage for language models. We will experiment with a variety of approaches to build the Danish subword tokenizer and conduct a comprehensive analysis of its impact on a Large Language Model (LLM). This analysis includes both quantitative comparisons using various metrics and benchmarks, and qualitative evaluations through manual assessments. We will compare the performance of the Danish-specific tokenizer with a variety of existing tokenizers, including general-purpose and language-specific ones.",
    image: "https://repository-images.githubusercontent.com/219035799/ab427b80-a502-11ea-8467-694f4e40dfa7", // If available, add an image related to the project
    tags: [
      "NLP",
      "Tokenizer",
      "Danish",
      "Machine Learning",
      "Subword Segmentation",
      "Language Models"
    ],
    category: "research",
    github: "",  // If available, add the link to the repository or related project
    webapp: "",  // If available, add the link to the project demo or website
  },
  // {
  //   id: 12,
  //   title: "Danish-Finetuned LLaMA3 Model on Instruction Dataset",
  //   date: "Jan 2024 - Aug 2024",
  //   description:
  //     "This project focuses on fine-tuning the LLaMA3 model specifically for the Danish language using an instruction-based dataset. By adapting LLaMA3, a state-of-the-art language model, to Danish instructions, we aim to enhance its performance on Danish language tasks. The fine-tuned model demonstrates improved contextual understanding and response accuracy for Danish instructions and is designed to be a robust tool for Danish language applications.",
  //   image: "https://developer-blogs.nvidia.com/wp-content/uploads/2024/04/dev-llama3-blog-1920x1080-1.png", // If available, add an image related to the project
  //   tags: [
  //     "LLaMA3",
  //     "Fine-Tuning",
  //     "Danish",
  //     "Instruction Dataset",
  //     "Language Models",
  //     "Machine Learning"
  //   ],
  //   category: "research",
  //   github: "",  // If available, add the link to the repository or related project
  //   webapp: "",  // If available, add the link to the project demo or website
  // },
  // {
  //   id: 13,
  //   title: "Danish-Finetuned Whisper Model",
  //   date: "Feb 2024 - Ongoing",
  //   description:
  //     "In this project, we fine-tuned OpenAI's Whisper model on Danish language datasets to enhance its transcription and translation capabilities for Danish. The customized Whisper model aims to deliver more accurate and contextually appropriate results when processing Danish speech and text, catering to various applications including speech-to-text and audio translation.",
  //   image: "https://developer-blogs.nvidia.com/wp-content/uploads/2022/06/ai-for-dev-blog-green-neon-wave-1600x950-2.jpg", // If available, add an image related to the project
  //   tags: [
  //     "Whisper",
  //     "Fine-Tuning",
  //     "Danish",
  //     "Speech-to-Text",
  //     "Machine Learning",
  //     "Audio Processing"
  //   ],
  //   category: "research",
  //   github: "",  // If available, add the link to the repository or related project
  //   webapp: "",  // If available, add the link to the project demo or website
  // }
];



export const Full = [
  {
    id: 10,
    title: "meoo - Your Digital Relative",
    date: "Jan 2023 - Ongoing",
    description:
      "meoo is an app designed to assist patients at all stages of their health journey. With AI-driven features, meoo offers automatic transcription and summarization of key points from meetings, intelligent summarization and recognition of appointments from files, and support for managing emotions and tracking the process. The app organizes and structures all features into a unified timeline, providing a comprehensive overview of the patient's journey.\n\nThe project is inspired by our personal experiences as relatives of individuals undergoing challenging medical conditions. We aimed to create a tool that alleviates the emotional burden and uncertainty faced by patients, families, and relatives during difficult times.",
    image: meoo, // Add an image related to the project if available
    tags: [
      "React Native",
      "Typescript",
      "Python",
      "PyTorch",
      "FastAPI",
      "AWS",
      "Azure"
    ],
    category: "app",
    github: "",  // If available, add the link to the repository or related project
    webapp: "https://meoo.dk/",  // If available, add the link to the project demo or website
  }
];



export const Design = [
  {
    id: 9,
    title: "UI/UX: Redesigning Byggedata",
    date: "Jan 2023 - Jul 2023",
    description:
      "This project was created as part of the university course 'Concept Development with Companies,' in collaboration with Molio. The goal was to redesign their online platform, Byggedata, to enhance usability and aesthetics based on user feedback. We aimed to improve clarity and streamline the search process, create a visually appealing and functional design, and ensure users remain engaged with the platform. The redesign was developed using Figma and tested with Maze, with contributions from my study group at the IT University of Copenhagen.",
    image: molio, // Add an image related to the project if available
    tags: [
      "UX",
      "UI",
      "User Research",
      "Prototyping",
      "User Testing",
      "Figma",
    ],
    category: "design",
    github: "",  // If available, add the link to the repository or related project
    webapp: "",  // If available, add the link to the project demo or website
  }
];


export const Other = [
  {
    id: 9,
    title: "Custom-Built 2-Ton Mobile Speaker",
    date: "Mar 2022 - Nov 2022",
    description:
      "This hobby project involved managing finances and securing sponsorships for a 2-ton mobile speaker, built from scratch using CNC components and powered by solar energy. With a budget of 150,000 kr., the project required meticulous planning and execution. We designed and built the speaker using CNC machinery, including creating vector files and G-code for precise CNC cutting of all components. The speaker was crafted from raw materials, ensuring high-quality audio performance and durability. We also integrated Arduino and Raspberry Pi systems to control music-synchronized lighting effects, enhancing the visual experience. I oversaw the entire build process, from designing the components and managing CNC cutting to integrating the electronics. Additionally, I coordinated sponsors and logistics for Roskilde Festival where the speaker was featured, ensuring smooth operations and successful events.",
    image: China, // Add an image related to the project if available
    tags: [
      "CNC",
      "Arduino",
      "Raspberry Pi",
      "Electronics",
      "Solar Power",
      "Custom Fabrication",
    ],
    category: "hobby project",
    github: "",  // If available, add the link to the repository or related project
    webapp: "",  // If available, add the link to the project demo or website
  },
  // {
  //   id: 10,
  //   title: "Comprehensive Cybersecurity Analysis and Penetration Testing of Zeeguu’s Backend API",
  //   date: "Sep 2023 - Dec 2023",
  //   description:
  //     "In this project, I conducted an in-depth cybersecurity analysis and penetration testing of Zeeguu’s backend API. The project involved the use of fuzzing techniques and CodeQL to identify potential vulnerabilities and security weaknesses within the application. The goal was to enhance the security posture of the API by uncovering and addressing issues that could be exploited by malicious actors.",
  //   image: Zeeguu, // Add an image related to the project
  //   tags: [
  //     "Cybersecurity",
  //     "Penetration Testing",
  //     "Fuzzing",
  //     "CodeQL",
  //   ],
  //   category: "academic project",
  //   github: "",  // If available, add the link to the repository or related project
  //   webapp: "",  // If available, add the link to the project demo or website
  // }
];


export const Projects = ({openModal,setOpenModal}) => {

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p> I've been diving into different sorts of projects, from AI to crafting a massive mobile speaker. I’ve fine-tuned language models, built full-stack applications, and designed systems. </p>

                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">AI/ML</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Full-Stack</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Design</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Others</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <CardContainer>
                          {AIML
                            .map((project) => (
                              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                            ))}
                        </CardContainer>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <CardContainer>
                          {Full
                            .map((project) => (
                              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                            ))}
                        </CardContainer>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <CardContainer>
                          {Design
                            .map((project) => (
                              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                            ))}
                        </CardContainer>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                    <CardContainer>
                          {Other
                            .map((project) => (
                              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                            ))}
                        </CardContainer>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
