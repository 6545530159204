import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/Group 18.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "AI Enthusiast", "Full-Stack Developer", "Software Designer" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
 
    // create variable s

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={20} md={8} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                {/* <span className="tagline">Welcome to my Portfolio</span> */}
                <h1>{`Hi! I'm Mikkel `} 
                  <span className="txt-rotate-container">
                    <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "AI Enthusiast", "Full-Stack Developer", "Software Designer"]'>
                      <span className="wrap">{text}</span>
                    </span>
                  </span>
                </h1>
                <p>I started out building speakers in my garage and experimenting with Arduino and Raspberry Pi. This sparked my love for programming and turned into a career in full-stack development, AI and cloud infrastructure. I love working on interesting projects and diving into the world of AI/NLP. I've trained models like LLaMA, Whisper, and T5 on different HPC's. I’m always open to chatting about tech, collaborating on projects, or discussing the latest trends. If you’re looking for someone who’s passionate about NLP & tech - feel free to reach out.</p>
                <button 
                onClick={() => window.open('https://www.linkedin.com/in/mikkel-kildeberg-17769b185/', '_blank', 'noopener,noreferrer')} 
            >
                Let’s Connect <ArrowRightCircle size={25} />
            </button>              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );  
}
